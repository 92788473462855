<template>
  <!-- Modal -->
  <div
    class="modal"
    id="searchModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Búsqueda avanzada de Usuario</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>Compañía:</label>
              <v-autocomplete
                v-model="item.cmp_id"
                :items="companias"
              ></v-autocomplete>
            </div>
            <div class="form-group">
              <label>Tipo de Usuario:</label>
              <v-autocomplete
                v-model="item.tus_id"
                :items="tpousus"
              ></v-autocomplete>              
            </div>
          </div>
          <div class="form-group text-center">
            <input type="checkbox" class="form-checkbox-input" id="usu_inactivo" v-model="item.usu_inactivo">
            <label for="usu_inactivo">Inactivo:</label>
          </div>            
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button
              type="submit"
              class="btn btn-primary"
              data-dismiss="modal"
              v-on:click="$emit('search', item)"
            >Buscar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      companias: [],
      tpousus: []
    };
  },
  mounted: function() {
  },
  created: function() {
    this.fetchCompania();
    this.fetchTpousu();
  },
  methods: {
    fetchCompania() {
      let uri = "/cmps";
      this.axios.defaults.headers.common[
        "Authorization"
      ] = sessionStorage.getItem("jwtToken");
      this.axios.get(uri).then(response => {
        this.companias = response.data.map(i => {
          return {
            text: i.cmp_nmbre,
            value: i._id
          }
        });
      });
    },
    fetchTpousu() {
      var uri = "/tpousus";
      this.axios.get(uri)
      .then(response => {
        this.tpousus = response.data.map(i => {
          return {
            text: i.tus_nmbre,
            value: i._id
          }
        });
      })
      .catch(err => {
        alert("No existen items para mostrar.");
      });
    }
  } // END METHODS
};
</script>

<style>
/*
.modal-dialog {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 500px;
  height: 300px;
}
*/
</style>